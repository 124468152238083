import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAt,
  faFlask,
  faBolt,
  faCode,
  faKeyboard,
  faCogs,
  faRocket,
} from '@fortawesome/free-solid-svg-icons'
import {
  faDiscord,
  faTwitter,
  faFacebookF,
  faMeetup,
  faSlackHash,
} from '@fortawesome/free-brands-svg-icons'

const makeIcon = icon => ({ size = 'lg' }) => (
  <FontAwesomeIcon icon={icon} size={size} />
)

export const Discord = makeIcon(faDiscord)
export const Twitter = makeIcon(faTwitter)
export const At = makeIcon(faAt)
export const Facebook = makeIcon(faFacebookF)
export const Meetup = makeIcon(faMeetup)
export const Slack = makeIcon(faSlackHash)

export const Flask = makeIcon(faFlask)
export const Bolt = makeIcon(faBolt)
export const Code = makeIcon(faCode)
export const Keyboard = makeIcon(faKeyboard)
export const Cogs = makeIcon(faCogs)
export const Rocket = makeIcon(faRocket)
