import React from 'react'
import { Slack, Twitter, At, Facebook, Meetup } from './icons'

class Footer extends React.Component {
  render() {
    return (
      <section id="footer">
        <div className="container">
          <header className="major">
            <h2>Sign up for our newsletter</h2>
          </header>
          <p>Your email will never be shared with third parties.</p>
          <form
            name="email-signup"
            method="POST"
            data-netlify="true"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <input type="hidden" name="form-name" value="email-signup" />
            <label htmlFor="newsletter-email" className="hideVisually">
              Your email
            </label>
            <input
              className="light"
              id="newsletter-email"
              name="newsletter-email"
              type="email"
              style={{ maxWidth: 350, marginRight: '1rem' }}
            />
            <button className="button special">Submit</button>
          </form>
        </div>
        <ul className="icons">
          <li>
            <a
              href="https://join.slack.com/t/lehilabs/shared_invite/enQtNDg5NDIwMzI2NjkxLWViZDE4MDgzNzY3OTFhMjg4OGRiOThiMjQ0NmYyNWY2Njc0MTFjZjU5MmVlZjY0YzY4ZmM5NGI1ODU5NmUzNzk"
              className="icon"
            >
              <Slack />
              <span className="hideVisually">
                Join the LehiLabs Slack workspace
              </span>
            </a>
          </li>
          <li>
            <a href="mailto:lehilabs@gmail.com" className="icon">
              <At />
              <span className="hideVisually">
                Email us at lehilabs@gmail.com
              </span>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/lehilabs" className="icon">
              <Twitter />
              <span className="hideVisually">Tweet us @lehilabs</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/Lehi-Labs-340942760001659/"
              className="icon"
            >
              <Facebook />
              <span className="hideVisually">Follow us on Facebook</span>
            </a>
          </li>
          <li>
            <a href="https://www.meetup.com/Lehi-Labs" className="icon">
              <Meetup />
              <span className="hideVisually">
                Check out our events on Meetup.com
              </span>
            </a>
          </li>
        </ul>
        <small className="copyright">Lehi Labs &copy; 2018</small>
      </section>
    )
  }
}

export default Footer
