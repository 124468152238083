import React from 'react'
import logo from '../assets/images/lehilabs.png'

class Header extends React.Component {
  render() {
    return (
      <section id="header">
        <div className="inner">
          <img src={logo} alt="Lehi Labs logo" style={{ width: 80 }} />
          <h1>
            We are <strong>Lehi Labs</strong>
          </h1>
          <p>A space for innovating and developing STEAM/STEM projects</p>
          <div className="actions">
            <div>
              <a href="#one" className="button scrolly">
                Learn more
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Header
